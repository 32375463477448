import React from "react";
import classes from "./TechnologySection.module.css";
import Card from "../Card";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import dashboard from "../../../assets/icons/techAndLogistics/dashboard.svg";
import orderManagement from "../../../assets/icons/techAndLogistics/ordermanagement.svg";
import warehouseManagement from "../../../assets/icons/techAndLogistics/warehouseManagement.svg";
import tracking from "../../../assets/icons/techAndLogistics/tracking.svg";

const TECH = [
  { icon: dashboard, name: "Dashboard", sectionId: "techFirstSection" },
  {
    icon: orderManagement,
    name: "Order management system",
    sectionId: "techSecondSection",
  },
  {
    icon: warehouseManagement,
    name: "Warehouse management system",
    sectionId: "techThirdSection",
  },
  {
    icon: tracking,
    name: "Tracking & reporting",
    sectionId: "techFourthSection",
  },
];

function TechnologySection({ onSectionSelect }) {
  return (
    <div id="homeSeventhSection" className={classes.technologySection}>
      {/* <h6>ENHANCE YOUR E-COMMERCE WITH TECHNOLOGY</h6> */}
      <h3>OUR TECHNOLOGY</h3>
      <div className={classes.cardContainer}>
        {TECH.map((item) => (
          <Card
            key={item.name}
            icon={item.icon}
            name={item.name}
            sectionId={item.sectionId}
            url="/our-technology"
            // onClick={onSectionSelect}
          />
        ))}
      </div>
    </div>
  );
}

export default TechnologySection;
