import React from "react";
import ImageSection from "../ImageSection";
import ContentSection from "../ContentSection";

function WarehouseManagement({ image, heading, content }) {
  return (
    <div id="techThirdSection" className="logisticsTechContentWrapper">
      <ContentSection heading={heading} content={content} />
      <ImageSection image={image} />
    </div>
  );
}

export default WarehouseManagement;
