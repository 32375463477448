import React from "react";
import Navbar from "../../Navbar";
import classes from "./HomeSection.module.css";
import HomeSlider from "./HomeSlider";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function HomeSection() {
  const navigate = useNavigate();

  function sendToContactUs() {
    navigate("/contact-us");
  }

  return (
    <>
      {/* <Navbar /> */}
      <div id="homeFirstSection" className={classes.homeSection}>
        {/* left container */}
        <div className={classes.leftContainer}>
          <h2>WHAT WE DO</h2>
          <h1>
            WE GROW YOUR <span className={classes.visionaries}>CREATION</span>{" "}
            INTO THE BRANDS OF TOMORROW
          </h1>
          <p>
            We are the distribution gateway for your amazing products in the
            Middle East across omni-sales channels, including e-commerce, retail
            stores, hotels, restaurants, cafes, and institutions.
          </p>
          <button className={classes.businessBtn} onClick={sendToContactUs}>
            LEARN MORE
          </button>
        </div>
        {/* right container */}
        <HomeSlider />
      </div>
    </>
  );
}

export default HomeSection;
