import React from "react";
import classes from "./IndustryCard.module.css";
import { GroupOutlined } from "@mui/icons-material";

function IndustryCard({ img, icon, name }) {
  return (
    <div className={classes.thumbnails}>
      <img src={img} alt="" loading="lazy" />
      <div className={classes.black}></div>
      <div className={classes.title}>
        <h6 style={{ whiteSpace: "pre-line" }}>{name}</h6>
      </div>
    </div>

    // <div className={classes.industryCard}>
    //   <div className={classes.industryImgCard}>
    //     <h4>{name}</h4>
    //   </div>
    // </div>
    // <div className={classes.industryCard}>
    //   {/* <GroupOutlined fontSize="large" /> */}
    //   <img src={icon} alt="industry-icon" />
    //   <hr />
    //   <h6 style={{ whiteSpace: "pre-line" }}>{name}</h6>
    // </div>
  );
}

export default IndustryCard;
