import React from "react";
import classes from "./ContentSection.module.css";

function ContentSection({ heading, content }) {
  return (
    <div className={classes.contentSection}>
      <h3>{heading}</h3>
      {/* <hr /> */}
      {/* <p>{content}</p> */}
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        suscipit arcu sit amet efficitur feugiat. Vestibulum ante ipsum primis
        in faucibus orci luctus et ultrices posuere cubilia curae; Nulla
        venenatis tellus id lorem maximus pharetra. Maecenas lobortis tellus sit
        amet scelerisque porttitor. Proin lobortis lobortis mauris, eu lobortis
        dui euismod vitae. Etiam vehicula accumsan nisi ut sagittis.
        Pellentesque a hendrerit neque. Duis id posuere orci. Nullam id lacus in
        est ullamcorper tincidunt quis tempus risus. Nunc commodo augue ac
        suscipit lacinia. Cras imperdiet ex vehicula tincidunt fermentum.
        Maecenas finibus, metus nec egestas condimentum, velit nisi lobortis
        metus, ut aliquam sem.
      </p>
    </div>
  );
}

export default ContentSection;
