import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/OurLogistics/Header";
import Warehousing from "../components/OurLogistics/Warehousing";
import BottomSection from "../components/OurLogistics/BottomSection";
import classes from "./OurLogistics.module.css";
import WhatsappFloatingButton from "../components/WhatsappFloatingButtton";
import GoToTop from "../components/GoToTop";
import OrderPicking from "../components/OurLogistics/OrderPicking";
import Inventory from "../components/OurLogistics/Inventory";
import Deliveries from "../components/OurLogistics/Deliveries";

import warehousing from "../assets/logistics/Warehousing 1.jpg";
import packing from "../assets/logistics/Packing.jpg";
import inventory from "../assets/logistics/Inventory.jpg";
import deliveries from "../assets/logistics/Deliveries.jpg";

function OurLogistics() {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.pathname);

  async function sendToSection(url, sectionId) {
    if (location.pathname !== url) {
      await navigate(url);
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    }
  }

  return (
    <>
      <GoToTop />
      <WhatsappFloatingButton />
      <Navbar onSectionSelect={sendToSection} />
      <div className={classes.logisticsSection}>
        <Header heading="Our Logistics" />
        {/* <hr /> */}
        <Warehousing image={warehousing} heading="Warehousing" content="" />
        {/* <hr /> */}
        <OrderPicking
          image={packing}
          heading="Order Picking and Packing"
          content=""
        />
        {/* <hr /> */}
        <Inventory
          image={inventory}
          heading="Inventory Management"
          content=""
        />
        {/* <hr /> */}
        <Deliveries image={deliveries} heading="Deliveries" content="" />
        {/* <BottomSection /> */}
      </div>
    </>
  );
}

export default OurLogistics;
