import React, { useEffect, useState, useRef } from "react";
import classes from "./Navbar.module.css";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import modistLogo from "../assets/logo.jpg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

function Navbar(props) {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [showTechMenu, setTechMenu] = useState(false);
  const [showAboutMenu, setAboutMenu] = useState(false);
  const [showLogisticsMenu, setLogisticsMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [showResTechMenu, setResTechMenu] = useState(false);
  const [showResAboutMenu, setResAboutMenu] = useState(false);
  const [showResLogisticsMenu, setResLogisticsMenu] = useState(false);

  const dropdownRef = useRef(null);
  const responsiveDropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAboutMenu(false);
        setTechMenu(false);
        setLogisticsMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleDropdown(dropdownName) {
    if (dropdownName === "about") {
      setAboutMenu((prev) => !prev);
    } else if (dropdownName === "technology") {
      setTechMenu((prev) => !prev);
    } else if (dropdownName === "logistics") {
      setLogisticsMenu((prev) => !prev);
    }
  }

  function responsiveNavbarHandler() {
    setOpenNavbar((prev) => !prev);
  }

  function sendToContactForm() {
    navigate("/contact-us");
  }

  async function sendToSection(url, sectionId) {
    console.log(url, sectionId);
    setOpenNavbar(false);
    setAboutMenu(false);
    setTechMenu(false);
    setLogisticsMenu(false);
    props.onSectionSelect(url, sectionId);
  }

  const hamburgerMenuBtn = (
    <span className={classes.menuBtn} onClick={responsiveNavbarHandler}>
      &#9776;
    </span>
  );

  const responsiveSideNavbar = (
    <div className={classes.dropdown}>
      <button
        to="#"
        className={classes.closeBtn}
        onClick={responsiveNavbarHandler}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </button>
      <div className={`${classes.dropdownLink} ${classes.dropdownMenu}`}>
        <p
          // to="/"
          // className={({ isActive }) => (isActive ? classes.active : "")}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => setResAboutMenu((prev) => !prev)}
        >
          About Us <KeyboardArrowDownOutlinedIcon fontSize="medium" />
        </p>
        {showResAboutMenu && (
          <div ref={responsiveDropdownRef} className={classes.dropdownContent}>
            <h6 onClick={() => sendToSection("/", "homeFirstSection")}>
              What we do
            </h6>
            <h6 onClick={() => sendToSection("/", "homeSecondSection")}>
              Why us
            </h6>
            <h6 onClick={() => sendToSection("/", "homeThirdSection")}>
              How we work
            </h6>
            <h6 onClick={() => sendToSection("/", "homeFourthSection")}>
              Our industry focus
            </h6>
            <h6 onClick={() => sendToSection("/", "homeFifthSection")}>
              Our geographic focus
            </h6>
            {/* <h6 onClick={() => sendToSection("/", "homeSixthSection")}>
              Our logistics
            </h6>
            <h6 onClick={() => sendToSection("/", "homeSeventhSection")}>
              Our technology
            </h6> */}
          </div>
        )}
      </div>
      <hr />
      <div className={`${classes.dropdownLink} ${classes.dropdownMenu}`}>
        <p
          onClick={() => sendToSection("/", "homeSixthSection")}
          // onClick={() => setResLogisticsMenu((prev) => !prev)}
          // to="/our-logistics"
          // className={({ isActive }) => (isActive ? classes.active : "")}
        >
          Our Logistics
          {/* <KeyboardArrowDownOutlinedIcon fontSize="medium" /> */}
        </p>
        {/* {showResLogisticsMenu && (
          <div ref={dropdownRef} className={classes.dropdownContent}>
            <h6
              onClick={() =>
                sendToSection("/our-logistics", "logisticsFirstSection")
              }
            >
              Warehousing
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-logistics", "logisticsSecondSection")
              }
            >
              Order picking & packing
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-logistics", "logisticsThirdSection")
              }
            >
              Inventory management
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-logistics", "logisticsFourthSection")
              }
            >
              Deliveries
            </h6>
          </div>
        )} */}
      </div>
      <hr />
      <div className={`${classes.dropdownLink} ${classes.dropdownMenu}`}>
        <p
          onClick={() => sendToSection("/", "homeSeventhSection")}
          // onClick={() => setResTechMenu((prev) => !prev)}
          // to="/our-technology"
          // className={({ isActive }) => (isActive ? classes.active : "")}
        >
          Our Technology
          {/* <KeyboardArrowDownOutlinedIcon fontSize="medium" /> */}
        </p>
        {/* {showResTechMenu && (
          <div ref={dropdownRef} className={classes.dropdownContent}>
            <h6
              onClick={() =>
                sendToSection("/our-technology", "techFirstSection")
              }
            >
              Dashboard
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-technology", "techSecondSection")
              }
            >
              Order management system
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-technology", "techThirdSection")
              }
            >
              Warehouse management system
            </h6>
            <h6
              onClick={() =>
                sendToSection("/our-technology", "techFourthSection")
              }
            >
              Tracking & reporting
            </h6>
          </div>
        )} */}
      </div>
      <hr />

      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <Link to="/contact-us">Contact Us</Link>
      </div>
    </div>
  );

  return (
    <div className={classes.navbarWrapper}>
      <div className={classes.navbar}>
        <Link to="/" className={classes.logo}>
          {/* <h1>MODIST</h1> */}
          <img alt="brand-logo" src={modistLogo} />
          <p>modern distributor</p>
        </Link>
        {!openNavbar ? hamburgerMenuBtn : responsiveSideNavbar}

        <nav className={classes.navMenu}>
          <ul>
            <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
              <p
                // to="#"
                // className={({ isActive }) => (isActive ? classes.active : "")}
                onClick={() => handleDropdown("about")}
              >
                About us <KeyboardArrowDownOutlinedIcon fontSize="medium" />
              </p>
              {showAboutMenu && (
                <div ref={dropdownRef} className={classes.dropdownContent}>
                  <h6 onClick={() => sendToSection("/", "homeFirstSection")}>
                    What we do
                  </h6>
                  <h6 onClick={() => sendToSection("/", "homeSecondSection")}>
                    Why us
                  </h6>
                  <h6 onClick={() => sendToSection("/", "homeThirdSection")}>
                    How we work
                  </h6>
                  <h6 onClick={() => sendToSection("/", "homeFourthSection")}>
                    Our industry focus
                  </h6>
                  <h6 onClick={() => sendToSection("/", "homeFifthSection")}>
                    Our geographic focus
                  </h6>
                  {/* <h6 onClick={() => sendToSection("/", "homeSixthSection")}>
                    Our logistics
                  </h6>
                  <h6 onClick={() => sendToSection("/", "homeSeventhSection")}>
                    Our technology
                  </h6> */}
                </div>
              )}
            </li>
            <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
              <p
                // to="#"
                // className={({ isActive }) => (isActive ? classes.active : "")}
                // onClick={() => handleDropdown("logistics")}
                onClick={() => sendToSection("/", "homeSixthSection")}
              >
                Our Logistics{" "}
                {/* <KeyboardArrowDownOutlinedIcon fontSize="medium" /> */}
              </p>
              {/* {showLogisticsMenu && (
                <div ref={dropdownRef} className={classes.dropdownContent}>
                  <h6
                    onClick={() =>
                      sendToSection("/our-logistics", "logisticsFirstSection")
                    }
                  >
                    Warehousing
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-logistics", "logisticsSecondSection")
                    }
                  >
                    Order picking & packing
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-logistics", "logisticsThirdSection")
                    }
                  >
                    Inventory management
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-logistics", "logisticsFourthSection")
                    }
                  >
                    Deliveries
                  </h6>
                </div>
              )} */}
            </li>
            <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
              <p
                // to="#"
                // className={({ isActive }) => (isActive ? classes.active : "")}
                // onClick={() => handleDropdown("technology")}
                onClick={() => sendToSection("/", "homeSeventhSection")}
              >
                Our Technology{" "}
                {/* <KeyboardArrowDownOutlinedIcon fontSize="medium" /> */}
              </p>
              {/* {showTechMenu && (
                <div ref={dropdownRef} className={classes.dropdownContent}>
                  <h6
                    onClick={() =>
                      sendToSection("/our-technology", "techFirstSection")
                    }
                  >
                    Dashboard
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-technology", "techSecondSection")
                    }
                  >
                    Order management system
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-technology", "techThirdSection")
                    }
                  >
                    Warehouse management system
                  </h6>
                  <h6
                    onClick={() =>
                      sendToSection("/our-technology", "techFourthSection")
                    }
                  >
                    Tracking & reporting
                  </h6>
                </div>
              )} */}
            </li>

            <li
              className={`${classes.navListItem} ${classes.sellBtn}`}
              onClick={sendToContactForm}
            >
              {/* <Link to="/contact-us"> */}
              Contact Us
              {/* </Link> */}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
