import React from "react";
import ImageSection from "../ImageSection";
import ContentSection from "../ContentSection";

function Dashboard({ image, heading, content }) {
  return (
    <div id="techFirstSection" className="logisticsTechContentWrapper">
      <ImageSection image={image} />
      <ContentSection heading={heading} content={content} />
    </div>
  );
}

export default Dashboard;
