import React from "react";
import { Link } from "react-router-dom";
// import chatIcon from "../../assets/logos/chatIcon.png";
import classes from "./WhatsappFloatingButton.module.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function WhatsappFloatingButton() {
  const phoneNumber = 971569902345;
  return (
    <>
      <div className={classes.whatsappIcon}>
        <Link
          to={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
          target="_blank"
        >
          <WhatsAppIcon fontSize="large" />
          {/* <img src={whatsappIcon} alt="whatsapp-icon" /> */}
        </Link>
      </div>
      {/* <div className={classes.messageIcon}>
        <Link to="/contactus">
          <img src={chatIcon} alt="message-us-icon" />
        </Link>
      </div> */}
    </>
  );
}

export default WhatsappFloatingButton;
