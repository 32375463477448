import React from "react";
import Navbar from "../components/Navbar";

function AboutUs() {
  return (
    <>
      <Navbar />
      <h1>About Us Page</h1>
    </>
  );
}

export default AboutUs;
