import React from "react";
import classes from "./DistributorsSection.module.css";
import IndustryCard from "./IndustryCard";
import fmcg from "../../../assets/icons/industries/FMCG.svg";
import cosmetics from "../../../assets/icons/industries/cosmetics.svg";
import electronics from "../../../assets/icons/industries/electronics.svg";
import apparrel from "../../../assets/icons/industries/apparel.svg";
import health from "../../../assets/icons/industries/health.svg";
import stationery from "../../../assets/icons/industries/stationery.svg";
import decor from "../../../assets/icons/industries/decor.svg";
import games from "../../../assets/icons/industries/games.svg";

import fmcgImg from "../../../assets/carousel/oil3.jpg";
import cosmeticsImg from "../../../assets/carousel/cosmetics1.jpg";
import pillsImg from "../../../assets/carousel/Pills.jpg";
import electronicsImg from "../../../assets/carousel/electronics1.jpg";

const INDUSTRIES = [
  { img: fmcgImg, icon: fmcg, name: "FMCG" },
  { img: cosmeticsImg, icon: cosmetics, name: "Beauty & \n Cosmetics" },
  { img: electronicsImg, icon: electronics, name: "Electronics \n & Gadgets" },
  // { img: fmcgImg, icon: apparrel, name: "Apparel & \n Accessories" },
  { img: pillsImg, icon: health, name: "Health & \n Fitness" },
  // { img: fmcgImg, icon: stationery, name: "Books & \n Stationery" },
  // { img: fmcgImg, icon: decor, name: "Home \n Decor" },
  // { img: fmcgImg, icon: games, name: "Games & \n Toys" },
];

function DistributorsSection() {
  return (
    <div id="homeFourthSection" className={classes.distributorsSection}>
      <h2 className={classes.smallHeader}>OUR INDUSTRY FOCUS</h2>
      {/* <div className={classes.contentDiv}>
        <h3>
          Distribution Solution <br /> that works great for
          <br /> any product.
        </h3>
        <p>
          From startups to multinationals, luxury brands to DTC. Our facility
          offers a secure, clean, temperature-controlled space with the most
          accurate picking, packing and shipping solutions.
        </p>
      </div> */}
      <div className={classes.cardsContainer}>
        {INDUSTRIES.map((item) => (
          <IndustryCard
            key={item.name}
            icon={item.icon}
            name={item.name}
            img={item.img}
          />
        ))}
      </div>
    </div>
  );
}

export default DistributorsSection;
