import React from "react";
import classes from "./ServiceSelector.module.css";

function ServiceSelector({ index, onSelect }) {
  return (
    <div className={classes.serviceSelector}>
      <p
        id="1"
        className={index === 0 ? classes.selectedService : ""}
        onClick={() => onSelect(0)}
      >
        Market Entry Evaluation
      </p>
      <p
        id="2"
        className={index === 1 ? classes.selectedService : ""}
        onClick={() => onSelect(1)}
      >
        Registration & Compliances
      </p>
      <p
        id="3"
        className={index === 2 ? classes.selectedService : ""}
        onClick={() => onSelect(2)}
      >
        Offline Sales
      </p>
      <p
        id="4"
        className={index === 3 ? classes.selectedService : ""}
        onClick={() => onSelect(3)}
      >
        Online Sales
      </p>
      <p
        id="5"
        className={index === 4 ? classes.selectedService : ""}
        onClick={() => onSelect(4)}
      >
        Local Logistics
      </p>
      <p
        id="6"
        className={index === 5 ? classes.selectedService : ""}
        onClick={() => onSelect(5)}
      >
        Real-Time Data Analytics
      </p>
    </div>
  );
}

export default ServiceSelector;
