// src/VerticalCarousel.js
import React, { useState, useEffect } from "react";
import classes from "./VerticalCarousel.module.css";
import one from "../assets/carousel/baby3.avif";
import two from "../assets/carousel/oil3.avif";
import three from "../assets/carousel/water1.avif";
import four from "../assets/carousel/electronics1.avif";

const VerticalCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 2000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes["vertical-carousel"]}>
      <div
        className={classes["carousel-content"]}
        style={{
          transform: `translateY(-${currentIndex * 25}%)`,
          transition: "transform 0.5s linear",
        }}
      >
        <div className={classes["carousel-item"]}>
          <img src={one} alt="1" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={two} alt="2" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={three} alt="3" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={four} alt="4" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={one} alt="1" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={two} alt="2" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={three} alt="3" />
        </div>
        <div className={classes["carousel-item"]}>
          <img src={four} alt="4" />
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
