import React, { useState } from "react";
import { BASE_URL } from "../../../apiConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import classes from "./ConnectSection.module.css";

function ConnectSection() {
  const [email, setEmail] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(email);

    try {
      const response = await axios.post(
        `${BASE_URL}/connect`,
        { email: email },
        { withCredentials: true }
      );
      // setData(response.data.message);
      if (response.status === 200) {
        setEmail("");
        return toast.success("Message sent successfully");
      } else {
        return toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please try again later.");
    }
  }

  return (
    <>
      <ToastContainer />
      <div className={classes.connectSection}>
        <h6>LET'S CONNECT</h6>
        <h3>
          LET'S GROW YOUR <br />
          CREATION, TOGETHER
        </h3>
        <form onSubmit={handleSubmit}>
          <div className={classes.emailForm}>
            <input
              required
              type="email"
              name="email"
              value={email}
              placeholder="Enter Your Email ID"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ConnectSection;
