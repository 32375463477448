import React from "react";
import classes from "./LogisticsSection.module.css";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Card from "../Card";
import warehousing from "../../../assets/icons/techAndLogistics/warehousing.svg";
import packing from "../../../assets/icons/techAndLogistics/packing.svg";
import inventory from "../../../assets/icons/techAndLogistics/inventory-management.svg";
import delivery from "../../../assets/icons/techAndLogistics/delivery.svg";

const LOGISTICS = [
  {
    icon: warehousing,
    name: "Warehousing",
    sectionId: "logisticsFirstSection",
  },
  {
    icon: packing,
    name: "Order picking & packing",
    sectionId: "logisticsSecondSection",
  },
  {
    icon: inventory,
    name: "Inventory management",
    sectionId: "logisticsThirdSection",
  },
  { icon: delivery, name: "Deliveries", sectionId: "logisticsFourthSection" },
];

function LogisticsSection({ onSectionSelect }) {
  return (
    <div id="homeSixthSection" className={classes.logisticsSection}>
      {/* <h6>ENHANCE YOUR E-COMMERCE WITH TECHNOLOGY</h6> */}
      <h3>OUR LOGISTICS</h3>
      <div className={classes.cardContainer}>
        {LOGISTICS.map((item) => (
          <Card
            key={item.name}
            icon={item.icon}
            name={item.name}
            sectionId={item.sectionId}
            url="/our-logistics"
            // onClick={onSectionSelect}
          />
        ))}
      </div>
    </div>
  );
}

export default LogisticsSection;
