import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/OurLogistics/Header";
import BottomSection from "../components/OurLogistics/BottomSection";
import classes from "./OurLogistics.module.css";
import WhatsappFloatingButton from "../components/WhatsappFloatingButtton";
import GoToTop from "../components/GoToTop";
import Dashboard from "../components/OurTechnology/Dashboard";
import OrderManagement from "../components/OurTechnology/OrderManagement";
import WarehouseManagement from "../components/OurTechnology/WarehouseManagement";
import TrackingReporting from "../components/OurTechnology/TrackingReporting";

import dashboard from "../assets/technology/Dashboard.jpg";
import orderManagement from "../assets/technology/order-management.jpg";
import warehouseManagement from "../assets/technology/Warehouse-management2.jpg";
import tracking from "../assets/technology/Tracking.jpg";

function OurTechnology() {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.pathname);

  async function sendToSection(url, sectionId) {
    if (location.pathname !== url) {
      await navigate(url);
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    }
  }

  return (
    <>
      <GoToTop />
      <WhatsappFloatingButton />
      <Navbar onSectionSelect={sendToSection} />
      <div className={classes.logisticsSection}>
        <Header heading="Our Technology" />
        {/* <hr /> */}
        <Dashboard image={dashboard} heading="Dashboard" content="" />
        {/* <hr /> */}
        <OrderManagement
          image={orderManagement}
          heading="Order Management System"
          content=""
        />
        {/* <hr /> */}
        <WarehouseManagement
          image={warehouseManagement}
          heading="Warehouse Management System"
          content=""
        />
        {/* <hr /> */}
        <TrackingReporting
          image={tracking}
          heading="Tracking and Reporting"
          content=""
        />
        {/* <BottomSection /> */}
      </div>
    </>
  );
}

export default OurTechnology;
