import React from "react";
import classes from "./GeographicSection.module.css";

function GeographicSection() {
  return (
    <div id="homeFifthSection" className={classes.geographicSection}>
      {/* <h6>LET'S CONNECT</h6> */}
      <h3>OUR GEOGRAPHIC FOCUS</h3>
      <img
        src={require("../../../assets/MapChart_Map-crop.png")}
        alt="map"
        loading="lazy"
      />
    </div>
  );
}

export default GeographicSection;
