import React from "react";
import classes from "./ImageSection.module.css";

function ImageSection({ image }) {
  return (
    <div className={classes.imgDiv}>
      <img className={classes.sectionImg} src={image} alt="warehousing" />
    </div>
  );
}

export default ImageSection;
