import React from "react";
import classes from "./Header.module.css";

function Header({ heading }) {
  return (
    <div className={classes.pageHeader}>
      <div className={classes.contentDiv}>
        <h1>{heading}</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          suscipit tempus mi, id finibus lorem rutrum sit amet. Phasellus
          accumsan enim velit, ut pulvinar tellus auctor at. Nulla facilisi.
          Maecenas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Vivamus suscipit tempus mi, id finibus lorem rutrum sit amet.
          Phasellus accumsan enim velit, ut pulvinar tellus auctor at.
        </p>
      </div>
    </div>
  );
}

export default Header;
