import React from "react";
import classes from "./ServiceContent.module.css";

function ServiceContent({ isVisible, service, description }) {
  return (
    <div
      className={`${classes.serviceContent} ${
        isVisible ? classes.slideIn : classes.slideOut
      }`}
    >
      {/* added the inline style to consider \n for next line */}
      <h3 style={{ whiteSpace: "pre-line" }}>{service}</h3>
      <hr />
      <p>{description}</p>
    </div>
  );
}

export default ServiceContent;
