import React from "react";
import ImageSection from "../ImageSection";
import ContentSection from "../ContentSection";

function TrackingReporting({ image, heading, content }) {
  return (
    <div
      id="techFourthSection"
      className="logisticsTechContentWrapper"
      style={{ backgroundColor: "#0035e9", color: "white" }}
    >
      <ImageSection image={image} />
      <ContentSection heading={heading} content={content} />
    </div>
  );
}

export default TrackingReporting;
