import React from "react";
import classes from "./Card.module.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

function Card({ icon, name, sectionId, url, onClick }) {
  return (
    <div
      className={classes.cardDiv}
      // onClick={() => onClick(url, sectionId)}
    >
      <img src={icon} alt="icon" loading="lazy" />
      {/* {icon} */}
      {/* <LocalShippingIcon /> */}
      <h5>{name}</h5>
      {/* <p>Know More</p> */}
    </div>
  );
}

export default Card;
