import React from "react";
import classes from "./BottomSection.module.css";

function BottomSection() {
  return (
    <div className={classes.bottomSection}>
      <div className={classes.upperDiv}>
        <h3>We Nurture & Grow Amazing Brands & Talent.</h3>
      </div>
      <div className={classes.lowerDiv}>
        <h3>
          Ready to <span className={classes.sell}>sell your business?</span>
        </h3>
        <button className={classes.contactBtn}>Contact us</button>
      </div>
    </div>
  );
}

export default BottomSection;
