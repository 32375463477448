import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomeSection from "./components/LandingPage/HomeSection_First/HomeSection";
import LandingPage from "./pages/LandingPage";
import ContactUs from "./pages/ContactUs";
import OurLogistics from "./pages/OurLogistics";
import OurTechnology from "./pages/OurTechnology";
import AboutUs from "./pages/AboutUs";
import HomeSlider from "./components/temp";
import VerticalCarousel from "./components/VerticalCarousel";

function App() {
  return (
    <>
      {/* <HomeSection /> */}
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/try" element={<VerticalCarousel />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-logistics" element={<OurLogistics />} />
          <Route path="/our-technology" element={<OurTechnology />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
